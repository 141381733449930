import React from "react";

import { NotFoundPage, getProperty } from "@lachevaliniere/shared";

import { useStaticShared } from "hooks";

const Template = () => {
  const staticShared = useStaticShared();

  const title = getProperty(staticShared, "data.notFoundTitle");
  const text = getProperty(staticShared, "data.notFoundText");
  const button = getProperty(staticShared, "data.notFoundButton");

  return <NotFoundPage title={title} text={text} button={button} />;
};

export default Template;
